import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulFlipbook(slug: { eq: $slug }) {
      slug
      title
      body {
        body
      }
    }
  }
`;

function Flipbook({ data }) {
  const { contentfulFlipbook } = data;
  const { slug, title, body } = contentfulFlipbook;

  return (
    <>
      <pre>
        This is a Flipbook slugged:
        {' '}
        {slug}
      </pre>
      <h1>
        {title}
      </h1>
      <p>{body.body}</p>
    </>
  );
}

Flipbook.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default Flipbook;
